import axios from 'axios';
import PDFViewer from 'pdf-viewer-vue/dist/vue2-pdf-viewer';

export default {
	name: 'ReadFile',
	props: {},
	data: () => ({
		errorPdf: null,
	}),
	computed: {
		urlPdf() {
			return this.$route.query?.url || null;
		},
	},
	watch: {},
	created() {},
	methods: {
		showErrorPdf(msm) {
			this.errorPdf = msm;
		},
		myrendered() {
			if (this.$route.query?.page) {
				this.$children[0].page = parseInt(this.$route.query?.page);
			}
		},
		async handleDownload(data) {
			let url = data.src;
			const base64String = await this.getPDF(url);
			const linkSource = `data:application/pdf;base64,${base64String}`;
			const downloadLink = document.createElement('a');
			const fileName = url.substring(url.lastIndexOf('/') + 1);
			downloadLink.href = linkSource;
			downloadLink.download = fileName;
			downloadLink.click();
		},
		async getPDF(url) {
			try {
				const response = await axios.get(url, {
					responseType: 'arraybuffer',
				});
				const base64String = Buffer.from(response.data, 'binary').toString(
					'base64'
				);
				return base64String;
			} catch (error) {
				console.error(error);
			}
		},
	},
	beforeDestroy() {},
	components: {
		PDFViewer,
	},
};
