<template>
	<read-file />
</template>
<script>
import ReadFile from '@/components/file/read/ReadFile.vue';

export default {
	name: 'ContractReportView',
	components: { ReadFile },
};
</script>
